@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ImageText";

#{$selector} {
    &__grid {
        position: relative;
        flex-direction: column-reverse !important;

        @include screenLargerThan($screen-lg) {
            flex-direction: row !important;
        }

        &--is-reverse {
            flex-direction: column-reverse !important;

            @include screenLargerThan($screen-lg) {
                flex-direction: row-reverse !important;
            }
        }
    }

    &__animated__border {
        position: relative;
        display: block;
        width: 3px;
        height: 0;
        margin: $space-xl auto 0 auto;
        background: linear-gradient($light--primary, $light--primaryLight, transparent);
        transition: height 600ms ease-out;

        &--dark {
            background: linear-gradient($light--primary, $light--primaryDark, transparent);
        }

        &--bottom-to-top--light {
            background: linear-gradient(transparent, $light--primaryLight, $light--primary);
        }

        &--bottom-to-top--dark {
            background: linear-gradient(transparent, $light--primaryDark, $light--primary);
        }

        &--is-visible {
            height: 100%;
        }

        &__wrapper {
            position: absolute;
            height: 100%;

            @include screenLargerThan($screen-lg) {
                position: relative;
                height: auto;
            }
        }
    }

    &__sub__headline {
        text-transform: uppercase;
    }

    &__content {
        @include text("base");
        display: flex;
        align-items: center;
        align-self: end;

        @include screenLargerThan($screen-xl) {
            align-self: center;
        }

        &__paragraph {
            color: $light--base;
            
            &--dark {
                color: $dark--base;
            }
        }
    }

    &__animated__container {
        width: 100%;
        max-width: 700px;
        min-width: 400px;
    }

    &__animation {
        width: 100%;
        height: 100%;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: end;
    }
}
