// elevation properties
// -----------------------------------------------------------------------------

$elevation--card: 0 2px 5px 0px rgba(77, 77, 77, 0.25);
$elevation--image: 0px 8px 16px -8px rgba(0, 0, 0, 0.30), 0px 13px 27px -5px rgba(50, 50, 93, 0.25);
$elevation--card--dark: 0 0 10px 3px rgba(255, 255, 255, 0.10);
$elevation--carousel: 0 2px 5px 0px rgba(77, 77, 77, 0.25);
$elevation--carousel--dark: 0 3px 10px 10px rgba(255, 255, 255, 0.10);
$elevation--mainnavigation: 0 2px 5px 0px rgba(77, 77, 77, 0.25);

$elevation--text--radius: -1px -1px 1px;
$elevation--text: $elevation--text--radius $light--lightGray;
$elevation--text--primary: $elevation--text--radius $light--primaryLight;
$elevation--text--secondary: $elevation--text--radius rgba($light--secondary, 0.5);
$elevation--text--teritary: $elevation--text--radius rgba($light--teritary, 0.5);
$elevation--text--white: $elevation--text--radius rgba($light--base, 0.5);

$elevation--text--dark: $elevation--text--radius rgba($white, 0.5);
$elevation--text--primary--dark: $elevation--text--radius rgba($dark--primaryLight, 0.5);
$elevation--text--secondary--dark: $elevation--text--radius rgba($dark--secondary, 0.5);
$elevation--text--teritary--dark: $elevation--text--radius rgba($dark--teritary, 0.5);
$elevation--text--white--dark: $elevation--text--radius rgba($dark--base, 0.5);
