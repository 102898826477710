@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostList";

#{$selector} {
    &__container {
        padding-top: $space-md;
        padding-bottom: $space-md;
    }

    &__loading {
        margin-top: $space-md;
    }
}
