@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".TeaserRow";

#{$selector} {
    &__header {
        padding-bottom: $space-lg;
    }

    &__sub_headline {
        text-transform: uppercase;
    }

    &__teaser {
        height: 100%;
        
        &__wrapper {
            height: 100%;
        }
    }
}
