@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".TeaserFeature";

#{$selector} {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 70px;

    &--is-0 {
        margin-right: -35px;
    }

    &--is-1 {
        margin-right: -20px;
    }

    &--is-2 {
        margin-right: -30px;
    }

    &--is-reverse {
        flex-direction: row-reverse;
        margin-right: 0;

        &--is-0 {
            margin-left: -35px;
        }

        &--is-1 {
            margin-left: -25px;
        }

        &--is-2 {
            margin-left: -30px;
        }
    }

    @include screenLargerThan($screen-md) {
        max-width: 400px;
        min-height: 130px;

        &--is-0 {
            margin-right: -50px;
        }
    
        &--is-1 {
            margin-right: -30px;
        }
    
        &--is-2 {
            margin-right: -35px;
        }
    
        &--is-reverse {
            &--is-0 {
                margin-left: -55px;
            }
    
            &--is-1 {
                margin-left: -30px;
            }
    
            &--is-2 {
                margin-left: -40px;
            }
        }
    }

    &__content {
        padding-top: $space-sm;
        padding-right: $space-lg;
        display: none;
        
        &--is-reverse {
            padding-left: $space-lg;
            padding-right: 0;
            text-align: left;
        }

        @include screenLargerThan($screen-lg) {
            display: block;
        }
    }

    &__text {
        @include text("meta");
        text-align: right;
        color: $light--darkGray;

        &--is-reverse {
            text-align: left;
        }

        &--is-dark {
            color: $dark--base;
        }
    }

    &__button {
        @include screenLargerThan($screen-md) {
            width: 52px !important;
            height: 52px !important;
        }
    }
}
