@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".NotFound";

#{$selector} {
    @include centered-container();
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screenLargerThan($screen-sm) {
        width: $screen-sm;
    }

    @include screenLargerThan($screen-md) {
        width: $screen-md;
    }

    &__animation {
        min-width: 700px;
    }

    &__button {
        margin-top: $space-lg !important;
    }
}
