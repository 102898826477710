@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostSidebarPostWidget";

#{$selector} {
    &__list {
        margin-top: $space-xl !important;
        padding: $space-xs $space-lg $space-lg $space-lg !important;
        border-radius: 15px;
        background-color: $light--backgroundWhite;

        &--dark {
            background-color: $dark--backgroundWhite;
        }
    }
}
