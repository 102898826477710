@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".FeatureCampaignContent";

#{$selector} {
    position: relative;

    &--is-first {
        padding-top: 0 !important;
    }

    &__animation {
        &__line {
            position: relative;
            display: block;
            width: 3px;
            height: 0;
            margin: $space-xl auto 0 auto;
            background: linear-gradient($light--primary, $light--primaryLight, transparent);
            transition: height 600ms ease-out;

            &__wrapper {
                height: (3 * $space-2xl);
            }

            &--light {
                background: linear-gradient($light--primary, $light--primaryLight, transparent);

                &--secondary {
                    background: linear-gradient($light--secondary, $light--teritary, transparent);
                }
            }

            &--dark {
                background: linear-gradient($light--primary, $light--primaryDark, transparent);

                &--secondary {
                    background: linear-gradient($dark--secondary, $dark--teritary, transparent);
                }
            }

            &--is-visible {
                height: calc(100% - (3 * $space-xl));

                @include screenLargerThan($screen-xl) {
                    height: calc(100% - $space-xl);
                }
            }

            &--top-to-bottom--light {
                background: linear-gradient(transparent, $light--primaryLight, $light--primary);

                &--secondary {
                    background: linear-gradient(transparent, $light--teritary, $light--secondary);
                }
            }

            &--top-to-bottom--dark {
                background: linear-gradient(transparent, $dark--primaryDark, $dark--primary );

                &--secondary {
                    background: linear-gradient(transparent, $dark--teritary, $dark--secondary);
                }
            }

            &--top-to-bottom-is-visible {
                height: 100%;
            }
        }

        &__container {
            min-width: 170px;
            margin-left: -70px;

            @include screenLargerThan($screen-lg) {
                min-width: 200px;
                margin-left: -40px;
            }
        }
    }

    &__sub__headline {
        padding-top: $space-2xl;
        padding-bottom: $space-xl;
        z-index: 2;

        @include screenLargerThan($screen-lg) {
            padding-top: (2 * $space-2xl);
            padding-bottom: (2 * $space-xl);
        }
    }

    &__content {
        color: $light--black !important;

        &--dark {
            color: $dark--black !important;
        }
    }

    &__icon {
        width: 80px;
        height: 80px;
        opacity: 0;

        @include screenLargerThan($screen-sm) {
            opacity: 0.5;
        }

        @include screenLargerThan($screen-lg) {
            width: 120px;
            height: 120px;
        }

        @include screenLargerThan($screen-xl) {
            width: 200px;
            height: 200px;
        }

        &__container {
            position: absolute;
            top: 70px;
            right: -40px;

            @include screenLargerThan($screen-lg) {
                top: auto;
                bottom: -30px;
            }

            @include screenLargerThan($screen-xl) {
                right: 0;
                bottom: 40px;
            }
        }

        &__wrapper {
            position: absolute;
            right: 60px;
            bottom: 0;
            z-index: 0;

            @include screenLargerThan($screen-xl) {
                bottom: -90px;
            }
        }
    }

    &__campaign {
        &__header {
            position: relative;
            padding-bottom: (2 * $space-2xl);

            @include screenLargerThan($screen-xl) {
                padding-bottom: (4 * $space-2xl);
            }
        }

        &__content {
            position: relative;
            z-index: 2;
        }

        &__image {
            width: 860px;
            height: 400px;
            box-shadow: none;

            @include screenLargerThan($screen-lg) {
                width: 100%;
                height: 420px;
            }

            @include screenLargerThan($screen-xl) {
                height: 650px;
            }

            & > img {
                object-fit: contain !important;
                object-position: left;
            }
        }

        &__qa {
            min-height: 10px;

            &__item {
                min-height: 125px;
                padding-bottom: $space-lg;

                @include screenLargerThan($screen-xl) {
                    max-width: 75%;
                }
            }
        }
    }
}
