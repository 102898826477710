@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ContentContainer";

#{$selector} {
    &--has-background {
        border-radius: $ui-background-border-radius $ui-background-border-radius 0 0;
    }

    &__content {
        max-width: 1920px;
        padding: $space-xl;
        margin: 0 auto;
        overflow: hidden;

        @include screenLargerThan($screen-md) {
            padding: $space-3xl $space-xl;
        }

        &--fullWidth {
            padding: 0;
            max-width: 100%;
        }

        &--spacing-lg {
            padding: $space-lg 0 !important;
        }

        &--spacing-md {
            padding: $space-md $space-xl !important;
        }

        &--spacing-sm {
            padding: $space-sm $space-xl !important;
        }

        &--spacing-xs {
            padding: $space-xs $space-xl !important;
        }

        &--spacing-none {
            padding: 0 $space-xl !important;
        }
    }
}
