@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostTeasers";

#{$selector} {
    &__skeleton {
        margin-bottom: $space-lg;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__content {
        margin-top: $space-md !important;
    }

    &__button {
        text-align: center;
        margin: $space-2xl 0;
    }
}
