@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Card";

#{$selector}

 {
    position: relative;
    color: $light--base;
    background-color: $light--backgroundWhite;
    border-radius: 16px;

    &--dark {
        color: $dark--base !important;
        background-color: $dark--backgroundWhite;
    }

    &--light--inherit {
        background-color: $light--background;
    }

    &--dark--inherit {
        background-color: $dark--background;
    }

    &--light--white {
        background-color: $light--backgroundWhite !important;
    }

    &--dark--white {
        background-color: $dark--backgroundWhite;
    }

    &__skeleton {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100% !important;
        border-radius: 16px;

        &--has-gradiant,
        &--has-background {
            border-radius: 9px 20px;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 9px 20px;

        &--dark {
            opacity: 0.7;
        }
    }

    &__link {
        display: flex;
        align-items: center;
    }

    &__content {
        position: relative;
        z-index: 2;
        padding: $space-lg;

        @include when-hovered() {
            text-decoration: none !important;
        }

        &--light {
            color: $light--base;

            @include when-hovered() {
                color: $light--primary;
            }

            &--primary {
                @include when-hovered() {
                    color: $light--primary;
                }
            }
        
            &--secondary {
                @include when-hovered() {
                    color: $light--secondary;
                }
            }
        
            &--teritary {
                @include when-hovered() {
                    color: $light--teritary;
                }
            }
        
            &--info {
                @include when-hovered() {
                    color: $light--gray;
                }
            }
        
            &--success {
                @include when-hovered() {
                    color: $light--success;
                }
            }
            
            &--warning {
                @include when-hovered() {
                    color: $light--warning;
                }
            }
        
            &--error {
                @include when-hovered() {
                    color: $light--error;
                }
            }
        
            &--white {
                @include when-hovered() {
                    color: $white;
                }
            }
        }

        &--dark {
            color: $dark--base;

            @include when-hovered() {
                color: $dark--primary;
            }

            &--primary {
                @include when-hovered() {
                    color: $dark--primary;
                }
            }
        
            &--secondary {
                @include when-hovered() {
                    color: $dark--secondary;
                }
            }
        
            &--teritary {
                @include when-hovered() {
                    color: $dark--teritary;
                }
            }
        
            &--info {
                @include when-hovered() {
                    color: $dark--gray;
                }
            }
        
            &--success {
                @include when-hovered() {
                    color: $dark--success;
                }
            }
            
            &--warning {
                @include when-hovered() {
                    color: $dark--warning;
                }
            }
        
            &--error {
                @include when-hovered() {
                    color: $dark--error;
                }
            }
        
            &--white {
                @include when-hovered() {
                    color: $white;
                }
            }
        }
    }

    &__gradiant {
        position: relative;
        border-radius: 9px 20px;
    }

    &--has-gradiant {
        position: relative;
    }

    &--has-gradiant,
    &--has-background {
        color: $white;
        border-radius: 9px 20px;
    }

    &--has-shadow {
        box-shadow: $elevation--card;

        &--dark {
            box-shadow: $elevation--card--dark;
        }
    }

    &--has-animation {
        @include fadeInAnimation();
    }

    &--is-clickable {
        cursor: pointer;

        @include when-hovered() {
            box-shadow: none;
            opacity: 0.7;
        }
    }
}
