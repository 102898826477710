@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AppStoreButtons";

#{$selector}

 {
    position: relative;
    width: 100%;

    &__link {
        position: relative;
        display: block;
        width: 138px;
        height: 46px;
        margin-top: $space-lg;
        margin-right: $space-lg;

        &__image {
            object-fit: contain;
        }

        @include screenLargerThan($screen-md) {
            margin-top: 0;
        }
    }

    @include screenLargerThan($screen-md) {
        display: flex;
    }
}
