@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".TeaserFeatures";

#{$selector} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $space-lg;

    &__image {
        position: relative;
        min-width: 300px;
        height: 486px;
        z-index: 0;

        @include screenLargerThan($screen-md) {
            min-width: 435px;
            height: 714px;
        }
    
        @include screenLargerThan($screen-xl) {
            min-width: 485px;
            height: 800px;
        }

        &__placeholder {
            object-fit: contain;
        }

        &__screenshot__holder {
            position: relative;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 385px;
            margin: 0 auto;
            top: 40px;
            border-radius: 30px;
            overflow: hidden;

            @include screenLargerThan($screen-md) {
                width: 267px;
                height: 577px;
                top: 53px;
                border-radius: 40px;
            }

            @include screenLargerThan($screen-xl) {
                width: 300px;
                height: 648px;
                top: 59px;
            }
        }

        &__screenshot__overlay {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(205px - (2 * $space-md));
            height: calc(420px - (2 * $space-lg));
            top: 40px;
            left: calc(50% - 90px);
            border-radius: 29px;
            padding: $space-lg $space-md;
            overflow: hidden;
            background-color: rgba($light--backgroundWhite, 0.9);

            &--is-dark {
                background-color: rgba($dark--backgroundWhite, 0.9);
            }

            @include screenLargerThan($screen-md) {
                width: calc(300px - (2 * $space-lg));
                height: calc(628px - (2 * $space-xl));
                padding: $space-xl $space-lg;
                top: 53px;
                left: calc(50% - 135px);
                border-radius: 36px;
            }

            &__content {
                @include text("meta");
                font-weight: 600;
                color: $light--base;

                @include screenLargerThan($screen-md) {
                    @include text("base");
                    font-weight: 600;
                }

                &--is-dark {
                    color: $dark--base;
                }
            }
        }
    }

    &__features {
        flex-grow: 1;

        &__feature {
            display: flex;
            justify-content: flex-end;

            &--is-right {
                justify-content: flex-start;
            }

            &__animation {
                z-index: 1;
            }
        }
    }
}
