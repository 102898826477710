@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".List";

#{$selector}

 {
    padding: 0;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: $space-xs 0;

        &__card {
            margin: $space-md 0;

            &__content {
                display: flex;
                align-items: center;
            }
        }

        &__link {
            @include text('base');
            display: flex;
            align-items: center;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            height: 30px;
            margin-top: $space-xs;
            margin-right: $space-xs;
            border-radius: 1000px;

            &__wrapper {
                display: flex;
                align-items: center;
                padding-right: $space-sm;
            }
        }

        &__title {
            @include text('base');

            &--card {
                margin: $space-sm 0 $space-xs $space-sm;
                font-weight: 700;
            }
        }

        &__desc {
            @include text('meta');
            margin: 0;
            padding: 0 0 $space-xs $space-sm;
        }

        &--is-clickable {
            cursor: pointer;
        }

        &--light {
            color: $light--base;

            &--is-clickable {
                color: $light--base;

                &--inherit {
                    @include when-hovered() {
                        color: $light--primary;
                    }
                }

                &--primary {
                    color: $light--primary;
                }
            
                &--secondary {
                    color: $light--secondary;
                }
            
                &--teritary {
                    color: $light--teritary;
                }

                &--info {
                    color: $light--gray;
                }

                &--success {
                    color: $light--success;
                }
                
                &--warning {
                    color: $light--warning;
                }

                &--error {
                    color: $light--error;
                }
            
                &--white {
                    color: $white;
            
                    @include when-hovered() {
                        color: $white;
                    }
                }
            
                &--primary,
                &--secondary,
                &--teritary,
                &--success,
                &--error,
                &--info,
                &--warning {
                    @include when-hovered() {
                        color: $light--base;
                    }
                }
            }
        }

        &--dark {
            color: $dark--base;

            &--is-clickable {
                color: $dark--base;

                &--inherit {
                    @include when-hovered() {
                        color: $dark--primary;
                    }
                }

                &--primary {
                    color: $dark--primary;
                }
            
                &--secondary {
                    color: $dark--secondary;
                }
            
                &--teritary {
                    color: $dark--teritary;
                }

                &--info {
                    color: $dark--gray;
                }

                &--success {
                    color: $dark--success;
                }
                
                &--warning {
                    color: $dark--warning;
                }

                &--error {
                    color: $dark--error;
                }
            
                &--white {
                    color: $white;
            
                    @include when-hovered() {
                        color: $white;
                    }
                }
            
                &--primary,
                &--secondary,
                &--teritary,
                &--success,
                &--error,
                &--info,
                &--warning {
                    @include when-hovered() {
                        color: $dark--base;
                    }
                }
            }
        }

        &--card {
            display: block;
            text-decoration: none;
        }
    }
}
