@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".VideoBanner";

$video-banner-screen-xl: calc($screen-xl - 50px);
$video-banner-screen-md: calc($screen-md - 70px);

#{$selector}

 {
    position: relative;
    height: calc(100vh - 80px);
    overflow: hidden;

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;

        &__container {
            position: absolute;
            inset: 0;

            &::before {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                opacity: 0.7;
                content: '';

                @include gradient(light, primaryOverlay);
            }

            &--dark::before {
                @include gradient(dark, primaryOverlay);
                opacity: 0.5;
            }
        }

        &--dark {
            opacity: 0.7;
        }
    }

    &__content {
        padding: $space-md $space-2xl;

        &__wrapper {
            @include centered-container();
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100vh;
        }

        @include screenLargerThan($screen-md) {
            padding: $space-2xl;
        }
    }

    &__desc {
        @include text("headline/sm");
        display: none;
        margin-top: $space-lg;

        @include screenLargerThan($screen-sm - 20px) {
            @include text("headline/sm");
            display: block;
        }

        @include screenLargerThan($screen-md) {
            max-width: 70%;
            @include text("headline/md");
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        width: 75%;

        @include screenLargerThan($screen-md) {
            margin-top: $space-xl;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        height: 50vh;

        @include screenLargerThan($screen-md) {
            justify-content: center;
        }

        @include screenLargerThan($video-banner-screen-xl) {
            width: 50% !important;
            height: 100vh;            
        }
    }

    &__image {
        position: relative;
        width: 100%;

        &__placeholder {
            position: relative;
            width: 100%;
            min-width: 440px;
            height: 50vh;

            &__image {
                object-fit: contain;
            }

            @include screenLargerThan($video-banner-screen-xl) {
                height: 70vh;
            }
        }

        &__screenshot {
            object-fit: contain;

            &__holder {
                position: absolute;
                top: 0.7vh;
                left: calc(50% - calc(50vh / 5));
                width: calc(50vh / 3.63);
                height: calc(50vh / 1.67);
                overflow: hidden;
                border-radius: 1.5vh;

                @include screenLargerThan($video-banner-screen-xl) {
                    top: 1vh;
                    left: calc(50% - calc(70vh / 5));
                    width: calc(70vh / 3.63);
                    height: calc(70vh / 1.66);
                    border-radius: 2vh;
                }
            }
        }
    }
}
