@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Image";

#{$selector} {
    position: relative;
    width: 100%;
    height: 280px;
    border-radius: $ui-image-border-radius;
    box-shadow: $elevation--image;
    overflow: hidden;

    @include screenLargerThan($screen-sm) {
        height: 350px;
    }

    @include screenLargerThan($screen-md) {
        height: 500px;
    }
}
