@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".LatestNewsTeaserRow";

#{$selector} {
    &__skeleton {
        width: 100%;
        margin-bottom: $space-lg;
        border-radius: $ui-background-border-radius;
    }

    &__header {
        padding-bottom: $space-xl;
    }

    &__sub_headline {
        text-transform: uppercase;
    }

    &__teaser {
        height: 100%;
    }

    &__button {
        width: 100%;
        text-align: center;
        margin-top: $space-lg;
    }
}
