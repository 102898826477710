
/*
    As example (light, primary)
    See defined variants in designsystem/config/gradients
*/
@mixin gradient($variant, $mode) {
    $selectedGradient: map-get($gradient, $variant);

    @if map-has-key($selectedGradient, $mode) {
        background: map-get($selectedGradient, $mode);
    } @else {
        @error "The variant #{$variant} or mode #{$mode} is not defined in $gradient.";
    }
}

@mixin gradientText($variant, $mode) {
    $selectedGradient: map-get($gradientText, $variant);

    @if map-has-key($selectedGradient, $mode) {
        background-image: map-get($selectedGradient, $mode);
    } @else {
        @error "The variant #{$variant} or mode #{$mode} is not defined in $gradientText.";
    }
}